import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { Box } from '@mui/system';
import { GridRowModel } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownButtonMenu, { DropdownMenuOption } from '../../../../../components/dropdownButtonMenu/DropdownButtonMenu';
import CellWrapper from '../../../components/CellWrapper';
import { RideRow } from '../../api/types';
import {
    RideChangeStatusKey,
    RideChangeStatusOptionsMap,
    getRideChangeStatusOptionsMap,
    rideChangeStatusLabel,
    rideChangeStatusTooltip,
} from '../ridesChangeStatusUtil';

interface ChangeStatusProps {
    row: GridRowModel;
    handleChangeStatusButtonClick: (rideId: string, optionsMap: RideChangeStatusOptionsMap) => void;
    handleReferenceAndRemarks: (data: RideRow) => void;
    handleDownloadRideOrder: (id: string) => void;
    handleRemoveDriver: (rideId: string, driverId: string) => void;
    handleDriverAndVehicle: (rideId: string) => void;
    isMobile: boolean;
}

export const ChangeStatusButton = ({
    row,
    isMobile,
    handleChangeStatusButtonClick,
    handleDownloadRideOrder,
    handleReferenceAndRemarks,
    handleRemoveDriver,
    handleDriverAndVehicle,
}: ChangeStatusProps) => {
    const { t } = useTranslation();

    const optionsMap: RideChangeStatusOptionsMap | null = useMemo(
        () => getRideChangeStatusOptionsMap(row.status, Boolean(row.driver)),
        [row.status, row.driver]
    );

    const changeStatusOptions = useMemo((): DropdownMenuOption[] => {
        const options: DropdownMenuOption[] = [];

        if (!optionsMap) {
            return options;
        }

        if (optionsMap.options.includes('ADD_DRIVER_AND_VEHICLE')) {
            options.push({
                id: RideChangeStatusKey.ADD_DRIVER_AND_VEHICLE,
                label: t(rideChangeStatusLabel.ADD_DRIVER_AND_VEHICLE),
                icon: BadgeOutlinedIcon,
                onClick: () => handleDriverAndVehicle(row.unid),
            });
        }

        if (optionsMap.options.includes('CHANGE_DRIVER_AND_VEHICLE')) {
            options.push({
                id: RideChangeStatusKey.CHANGE_DRIVER_AND_VEHICLE,
                label: t(rideChangeStatusLabel.CHANGE_DRIVER_AND_VEHICLE),
                icon: BadgeOutlinedIcon,
                onClick: () => handleDriverAndVehicle(row.unid),
            });
        }

        if (optionsMap.options.includes('UNASSIGN_DRIVER')) {
            options.push({
                id: RideChangeStatusKey.UNASSIGN_DRIVER,
                label: t(rideChangeStatusLabel.UNASSIGN_DRIVER),
                icon: DeleteOutlineOutlinedIcon,
                onClick: () => handleRemoveDriver(row.id, row.driver?.id),
            });
        }

        if (optionsMap.options.includes('UNASSIGN_DRIVER_AND_VEHICLE')) {
            options.push({
                id: RideChangeStatusKey.UNASSIGN_DRIVER_AND_VEHICLE,
                label: t(rideChangeStatusLabel.UNASSIGN_DRIVER_AND_VEHICLE),
                icon: DeleteOutlineOutlinedIcon,
                onClick: () => handleRemoveDriver(row.id, row.driver?.id),
            });
        }

        if (optionsMap.options.includes('REFERENCES_AND_REMARKS')) {
            options.push({
                id: RideChangeStatusKey.REFERENCES_AND_REMARKS,
                label: t(rideChangeStatusLabel.REFERENCES_AND_REMARKS),
                icon: RequestQuoteOutlinedIcon,
                onClick: () => handleReferenceAndRemarks(row as RideRow),
            });
        }

        if (optionsMap.options.includes('DOWNLOAD_RIDE_ORDER')) {
            options.push({
                id: RideChangeStatusKey.DOWNLOAD_RIDE_ORDER,
                label: t(rideChangeStatusLabel.DOWNLOAD_RIDE_ORDER),
                icon: FileDownloadOutlinedIcon,
                onClick: () => handleDownloadRideOrder(row.id),
            });
        }

        return options;
    }, [handleDownloadRideOrder, handleReferenceAndRemarks, handleDriverAndVehicle, handleRemoveDriver, optionsMap, row, t]);

    const mainButtonTooltip = useMemo(
        () => (optionsMap ? t(rideChangeStatusTooltip[optionsMap.mainButton] as string) : undefined),
        [optionsMap, t]
    );

    if (!optionsMap) {
        return null;
    }

    return (
        <CellWrapper>
            <Box display="flex" justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                <DropdownButtonMenu
                    buttonLabel={rideChangeStatusLabel[optionsMap.mainButton]}
                    onClick={() => handleChangeStatusButtonClick(row.id, optionsMap)}
                    options={changeStatusOptions}
                    tooltip={mainButtonTooltip}
                    tooltipWidth={130}
                />
            </Box>
        </CellWrapper>
    );
};
