import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import config from '../../config';
import TokenError from '../../constants/TokenError';
import {
    ACCEPT_INVITE,
    AUTHENTICATE,
    AVAILABILITY,
    BASE_URL_PATH,
    BOOKING_TOOL,
    DOCUMENTS,
    DRIVERS,
    VEHICLES,
    EXPIRED_INVITE_TOKEN,
    EXPIRED_PASSWORD_TOKEN,
    FORGOT_PASSWORD,
    INVALID_AUTH_CODE,
    INVALID_COOKIE,
    INVALID_INVITE_TOKEN,
    INVALID_PASSWORD_TOKEN,
    LOG_IN,
    LOG_IN_NEW,
    REPORTS,
    RESET_PASSWORD,
    RIDES,
    USERS,
    INVOICES,
    SETTINGS,
    UPDATE_RIDE,
} from '../../constants/urlPaths';
import AcceptInvite from '../../pages/acceptInvite/AcceptInvite';
import Availability from '../../pages/availability/Availability';
import BookingTool from '../../pages/bookingTool/BookingTool';
import CustomerExport from '../../pages/customerExport/CustomerExport';
import Documents from '../../pages/documents/Documents';
import Drivers from '../../pages/drivers/Drivers';
import InvalidAuthCode from '../../pages/errorPages/InvalidAuthCode';
import InvalidCookie from '../../pages/errorPages/InvalidCookie';
import InvalidInviteToken from '../../pages/errorPages/InvalidInviteToken';
import InvalidPasswordToken from '../../pages/errorPages/InvalidPasswordToken';
import NotFound from '../../pages/errorPages/NotFound';
import TooManyRequest from '../../pages/errorPages/TooManyRequest';
import UnauthorizedUser from '../../pages/errorPages/UnauthorizedUser';
import ForgotPassword from '../../pages/forgotPassword/ForgotPassword';
import Invoices from '../../pages/invoices/Invoices';
import Login from '../../pages/login/Login';
import PageContainer from '../../pages/pageContainer/PageContainer';
import Reports from '../../pages/reports/Reports';
import ResetPassword from '../../pages/resetPassword/ResetPassword';
import RidePageSwitch from '../../pages/ride/components/RidePageSwitch';
import UpdateRide from '../../pages/ride/customer/UpdateRide';
import RidesPageSwitch from '../../pages/rides/components/ridesPageSwitch/RidesPageSwitch';
import { Settings } from '../../pages/settings/Settings';
import { UserRoles } from '../../pages/users/api/types';
import Users from '../../pages/users/Users';
import Vehicles from '../../pages/vehicles/Vehicles';
import { ERROR_NOT_AUTHORIZED, ERROR_NOT_FOUND, ERROR_TOO_MANY_REQUESTS } from '../../services/routes';
import { AccountType } from '../../services/types';
import theme from '../../styles/theme';
import Authenticate from '../authenticate/Authenticate';
import PrivateRoute from '../privateRoute/PrivateRoute';

const useStyles = makeStyles({
    mainContainer: { padding: '0rem 4rem 2rem 4rem' },
    mainContainerRide: { padding: '0rem 2rem 2rem 2rem' },
    mainContainerMobile: { padding: '1rem' },
});

const usePrivateRoutes = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { useSettingsPage } = useFlags();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [
        <PrivateRoute
            key="ride"
            path={`${RIDES}/:rideId`}
            mainClassName={isMobile ? classes.mainContainerMobile : classes.mainContainerRide}
            component={RidePageSwitch}
        />,
        <PrivateRoute
            key="change-ride"
            path={`${UPDATE_RIDE}/:rideId`}
            mainClassName={isMobile ? classes.mainContainerMobile : classes.mainContainerRide}
            component={UpdateRide}
        />,
        <PrivateRoute key="rides" path={RIDES} component={RidesPageSwitch} />,
        <PrivateRoute
            key="drivers"
            path={DRIVERS}
            component={Drivers}
            mainClassName={isMobile ? classes.mainContainerMobile : classes.mainContainer}
            allowedAccountType={[AccountType.SUPPLIER]}
        />,
        <PrivateRoute
            key="vehicles"
            path={VEHICLES}
            component={Vehicles}
            mainClassName={isMobile ? classes.mainContainerMobile : classes.mainContainer}
            allowedAccountType={[AccountType.SUPPLIER]}
        />,
        <PrivateRoute
            key="availability"
            path={AVAILABILITY}
            component={Availability}
            allowedAccountType={[AccountType.SUPPLIER]}
        />,
        <PrivateRoute key="reports" path={REPORTS} component={Reports} allowedAccountType={[AccountType.SUPPLIER]} />,
        <PrivateRoute key="documents" path={DOCUMENTS} component={Documents} />,

        <PrivateRoute
            key="invoices"
            path={INVOICES}
            component={Invoices}
            allowedAccountType={[AccountType.SUPPLIER]}
            allowedUserRoles={[UserRoles.SEE_INVOICES]}
        />,

        <Route key="booking-tool" path={BOOKING_TOOL}>
            <PageContainer>
                <BookingTool />
            </PageContainer>
        </Route>,
        <PrivateRoute
            key="users"
            path={USERS}
            component={Users}
            mainClassName={isMobile ? classes.mainContainerMobile : classes.mainContainer}
        />,
        useSettingsPage && (
            <PrivateRoute
                key="settings"
                path={SETTINGS}
                component={Settings}
                mainClassName={isMobile ? classes.mainContainerMobile : classes.mainContainer}
            />
        ),
        <PrivateRoute key="export" path="/export" component={CustomerExport} />,
    ];
};

const Routes: FunctionComponent = () => {
    const history = useHistory();

    return (
        <Switch>
            {usePrivateRoutes()}
            <Route
                exact
                path={BASE_URL_PATH}
                render={() => {
                    history.push(RIDES);
                    return null;
                }}
            />
            <Route
                path={LOG_IN}
                render={() => {
                    window.location.href = config.signInUrl;
                    return null;
                }}
            />
            <Route key="log-in" path={LOG_IN_NEW}>
                <Login />
            </Route>
            ,
            <Route path={INVALID_AUTH_CODE} component={InvalidAuthCode} />
            <Route path={INVALID_COOKIE} component={InvalidCookie} />
            <Route path={INVALID_INVITE_TOKEN}>
                <InvalidInviteToken errorType={TokenError.NotFound} />
            </Route>
            <Route path={EXPIRED_INVITE_TOKEN}>
                <InvalidInviteToken errorType={TokenError.Expired} />
            </Route>
            <Route path={AUTHENTICATE}>
                <Authenticate />
            </Route>
            <Route path={INVALID_PASSWORD_TOKEN}>
                <InvalidPasswordToken errorType={TokenError.NotFound} />
            </Route>
            <Route path={EXPIRED_PASSWORD_TOKEN}>
                <InvalidPasswordToken errorType={TokenError.Expired} />
            </Route>
            <Route path={FORGOT_PASSWORD} component={ForgotPassword} />
            <Route path={RESET_PASSWORD} component={ResetPassword} />
            <Route path={ACCEPT_INVITE} component={AcceptInvite} />
            <Route path={ERROR_NOT_AUTHORIZED} component={UnauthorizedUser} />
            <Route path={ERROR_TOO_MANY_REQUESTS} component={TooManyRequest} />
            <Route key={ERROR_NOT_FOUND} path="*" component={NotFound} />
        </Switch>
    );
};

export default Routes;
