import { TextField, ModalAction as Modal } from '@get-e/react-components';
import { Face, PhoneOutlined } from '@mui/icons-material';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import {
    ADD_OR_EDIT_DRIVER_NAME_SURNAME_INPUT,
    ADD_OR_EDIT_DRIVER_PHONE_NUMBER_INPUT,
    ADD_OR_EDIT_DRIVER_PHOTO_INPUT,
    ADD_OR_EDIT_DRIVER_REMOVE_PHOTO_INPUT,
    ADD_OR_EDIT_DRIVER_SAVE,
} from '../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../constants/colors';
import { getCountryCode } from '../../../../helpers/getCountryCode';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import { AddDriverRequest } from '../../../drivers/api/types';
import { ImageUrl } from '../../../vehicles/api/types';
import { useUploadedImageUrls } from '../../../vehicles/api/useUploadedImageUrls';
import ImageUploader from '../../../vehicles/components/imageUploader/ImageUploader';

const useStyles = makeStyles(styleTheme => ({
    addDriverModal: { '& .MuiDialog-container .MuiPaper-root': { maxWidth: '600px' } },
    formField: {
        marginBottom: '1.25rem',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        [styleTheme.breakpoints.down('md')]: { flexDirection: 'column' },
    },
    imageUploaderContainer: {
        marginTop: '-55px',
        [styleTheme.breakpoints.down('md')]: {
            marginTop: 0,
            marginBottom: '3rem',
        },
    },
    photoUploadContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        textAlign: 'center',
        width: '200px',
        cursor: 'pointer',
    },
    faceIcon: {
        color: COLORS.SLATE_GREY,
        fontSize: '40px',
    },
    photoUploadDescription: {
        fontSize: '12px',
        color: 'gray',
        '& span': {
            textDecoration: 'underline',
            display: 'block',
            fontWeight: 700,
        },
    },
}));

interface AddDriverFields {
    name: string;
    mobile: string;
}

interface AddDriverFormErrors {
    name: InputError | null;
    mobile: InputError | null;
}

interface AddDriverModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    onAddDriver: (driverInformation: AddDriverRequest) => void;
    isSaving?: boolean;
}

const AddDriverModal = ({ isModalOpen, onClose, onAddDriver, isSaving }: AddDriverModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [files, setFiles] = useState<string[]>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoadingImages, setIsLoadingImages] = useState(false);

    const [values, setValues] = useState<AddDriverFields>({
        name: '',
        mobile: '',
    });

    const [formErrors, setFormErrors] = useState<AddDriverFormErrors>({
        name: null,
        mobile: null,
    });

    const handleChange = <T extends keyof AddDriverFields>(key: T, newValue: AddDriverFields[T] & string): void => {
        setValues({
            ...values,
            [key]: newValue,
        });

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    const validateFields = (): boolean => {
        const validatedName = isFilledString(values.name, InputError.Empty);
        const validatedMobile = isFilledString(values.mobile, InputError.Empty);

        const fieldErrors: AddDriverFormErrors = {
            name: validatedName.isValid ? null : validatedName.error,
            mobile: validatedMobile.isValid ? null : validatedMobile.error,
        };

        const isValid = Object.values(fieldErrors).every(error => error === null);

        !isValid && setFormErrors(fieldErrors);

        return isValid;
    };

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        setIsSubmitted(true);
    };

    const resultsOfImages = useUploadedImageUrls(isSubmitted, files);

    useEffect(() => {
        if (!isSubmitted) {
            return;
        }

        const allSettled = resultsOfImages.every(result => result.isSuccess || result.isError);

        if (allSettled) {
            const successfulUploads = resultsOfImages.filter(result => result.isSuccess).map(result => result.data) as ImageUrl[];

            const newDriver = {
                name: values.name,
                phoneNumber: values.mobile,
                pictureUrl: successfulUploads[0]?.url,
            } as AddDriverRequest;

            onAddDriver(newDriver);
            setIsSubmitted(false);
            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_SAVE);
        }

        setIsLoadingImages(resultsOfImages.some(result => result.isLoading || result.isFetching));
    }, [resultsOfImages, isSubmitted, values, onAddDriver]);

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t('pages.rides.addDriver')}
            confirmButtonLabel={t('buttonName.addDriver')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isSaving || isLoadingImages}
            maxWidth="sm"
            modalContentClassName={classes.addDriverModal}
        >
            <Grid className={classes.contentContainer}>
                <Grid className={classes.imageUploaderContainer} marginRight={!files.length ? '16px' : 0}>
                    <ImageUploader
                        boxWidth="154px"
                        boxHeight="154px"
                        isDriverPage
                        content={
                            <Grid className={classes.photoUploadContainer}>
                                <Face className={classes.faceIcon} />
                                <Typography className={classes.photoUploadDescription}>
                                    {t('pages.rides.frameYourFace')} <span>{t('pages.rides.uploadFile')}</span>
                                </Typography>
                            </Grid>
                        }
                        setFiles={setFiles}
                        isLoading={isLoadingImages}
                        logPhotoInputEvent={() => {
                            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_PHOTO_INPUT);
                        }}
                        logPhotoDeleteEvent={() => {
                            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_REMOVE_PHOTO_INPUT);
                        }}
                    />
                </Grid>
                <Box>
                    <TextField
                        className={classes.formField}
                        error={formErrors.name !== null}
                        helperText={formErrors.name ? t(formErrors.name) : null}
                        label={t('form.fields.nameAndSurname')}
                        name="name"
                        onChange={event => {
                            handleChange('name', event.target.value);
                            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_NAME_SURNAME_INPUT);
                        }}
                        required
                        autoComplete="off"
                        value={values.name}
                    />
                    <MuiTelInput
                        style={{ marginBottom: formErrors?.mobile !== null ? '1.25rem' : '2.5rem' }}
                        value={values.mobile}
                        defaultCountry={getCountryCode()}
                        label={t('form.fields.mobile')}
                        className={classes.formField}
                        name="mobile"
                        onChange={value => {
                            handleChange('mobile', value);
                            logAmplitudeEvent(ADD_OR_EDIT_DRIVER_PHONE_NUMBER_INPUT);
                        }}
                        error={formErrors?.mobile !== null}
                        helperText={formErrors?.mobile && t(formErrors.mobile)}
                        variant="filled"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PhoneOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Grid>
        </Modal>
    );
};

export default AddDriverModal;
