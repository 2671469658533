import { Spinner, TextField, IconedData } from '@get-e/react-components';
import CheckIcon from '@mui/icons-material/Check';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Grid, Box, Typography, InputAdornment, Tooltip, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { SetStateAction, Dispatch, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import { getAirlineIataImage } from '../../../../services/routes';
import { PrimaryTimeZone } from '../../../../services/types';
import theme from '../../../../styles/theme';
import { FlightDetails } from '../../../rides/api/types';

const useStyles = makeStyles(() => ({
    centerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > div > span': { fontWeight: 700 },
    },
    noFlightTracking: {
        '& .MuiInputBase-root': {
            borderBottom: `2px solid ${COLORS.ORANGE_TEXT}`,
            '&:hover:before': { borderBottom: 'none' },
            '&:before': { borderBottom: 'none' },
            '&:after': { borderBottom: 'none' },
            '&.Mui-focused': { borderBottom: `2px solid ${COLORS.ORANGE_TEXT}` },
        },
    },
    flightTracking: {
        '& .MuiInputBase-root': {
            borderBottom: `2px solid ${COLORS.GREEN}`,
            '&:hover:before': { borderBottom: 'none' },
            '&:before': { borderBottom: 'none' },
            '&:after': { borderBottom: 'none' },
            '&.Mui-focused': { borderBottom: `2px solid ${COLORS.GREEN}` },
        },
    },
}));

const AIRLINE_IMAGE_WIDTH = 75;
const AIRLINE_IMAGE_HEIGHT = 25;

export interface RideError {
    pickUpDate: InputError | null;
    pickUpTime: InputError | null;
    travellers: InputError | null;
    luggages: InputError | null;
    pickUpLocation: InputError | null;
    dropOffLocation: InputError | null;
}

export const initialRideErrors = {
    pickUpDate: null,
    pickUpTime: null,
    travellers: null,
    luggages: null,
    pickUpLocation: null,
    dropOffLocation: null,
};

export interface Ride {
    id: string;
    flightNumber: string;
    flightDetails: FlightDetails;
    primaryTimeZone: PrimaryTimeZone | null;
}

export const initialRide = {
    id: uuid(),
    flightNumber: '',
    flightDetails: {} as FlightDetails,
    primaryTimeZone: null,
} as Ride;

interface ChangeFlightNumberProps {
    handleFlightNumberFinishing: () => void;
    isLoadingFlight?: boolean;
    isErrorFlight?: boolean;
    isUtc: boolean;
    flightDetails: FlightDetails;
    setFlightDetails: Dispatch<SetStateAction<FlightDetails>>;
    flightNumber: string;
    setFlightNumber: Dispatch<SetStateAction<string>>;
}

const ChangeFlightNumber = ({
    handleFlightNumberFinishing,
    isLoadingFlight,
    isErrorFlight,
    isUtc,
    flightDetails,
    flightNumber,
    setFlightNumber,
}: ChangeFlightNumberProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [imageError, setImageError] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isFlightTracked = !isErrorFlight && Boolean(flightDetails.number);

    const flightDepartureDateTime = useMemo(() => {
        const dateTime = isUtc ? flightDetails.departure?.datetimeZulu : flightDetails.departure?.datetimeLocal;

        return dateTime && dayjs(dateTime).utc().format(DATE_FORMATS['DD MMM at HH:mm A']);
    }, [isUtc, flightDetails.departure?.datetimeLocal, flightDetails.departure?.datetimeZulu]);

    const flightArrivalDateTime = useMemo(() => {
        const dateTime = isUtc ? flightDetails.arrival?.datetimeZulu : flightDetails.arrival?.datetimeLocal;

        return dateTime && dayjs(dateTime).utc().format(DATE_FORMATS['DD MMM at HH:mm A']);
    }, [isUtc, flightDetails.arrival?.datetimeLocal, flightDetails.arrival?.datetimeZulu]);

    const airlineAbbreviation = useMemo(() => flightNumber.replace(/[^a-zA-Z]/g, ''), [flightNumber]);

    const handleImageError = () => {
        setImageError(true);
    };

    const flightIcon = useMemo(() => {
        if (isFlightTracked) {
            return airlineAbbreviation && flightDepartureDateTime && !imageError ? (
                <img
                    src={getAirlineIataImage(airlineAbbreviation, AIRLINE_IMAGE_WIDTH, AIRLINE_IMAGE_HEIGHT)}
                    onError={handleImageError}
                    alt="Airline"
                />
            ) : (
                <CheckIcon style={{ color: COLORS.GREEN }} />
            );
        }

        return flightNumber ? <DangerousOutlinedIcon style={{ color: COLORS.ORANGE_TEXT }} /> : <></>;
    }, [isFlightTracked, flightNumber, airlineAbbreviation, flightDepartureDateTime, imageError]);

    return (
        <>
            <Box
                sx={{
                    marginBottom: '1rem',
                    marginTop: '1rem',
                }}
            >
                <Typography
                    style={{
                        fontWeight: 700,
                        color: COLORS.BLUE_DARK,
                    }}
                >
                    Flight information
                </Typography>
            </Box>
            <Grid gap={isMobile ? 2 : 4} display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                <Grid item xs={12}>
                    <TextField
                        label={t('pages.singleRide.fields.flightNumber')}
                        value={flightNumber}
                        onChange={event => {
                            setFlightNumber(event.target.value);
                            handleFlightNumberFinishing();
                        }}
                        className={clsx({
                            [classes.flightTracking]: isFlightTracked,
                            [classes.noFlightTracking]: !isFlightTracked && flightNumber !== '',
                        })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip
                                        title={
                                            isFlightTracked
                                                ? flightDetails.airlineName
                                                : t('pages.singleRide.fields.flightNotTracked')
                                        }
                                        arrow
                                        placement="top"
                                    >
                                        {isLoadingFlight ? <Spinner size={24} /> : flightIcon}
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {isFlightTracked && flightDepartureDateTime && (
                        <Box className={classes.centerContainer}>
                            <IconedData
                                icon={FlightTakeoffIcon}
                                data={flightDetails.departure?.iata}
                                dataColor={COLORS.BLACK}
                                tooltip={flightDetails.departure?.name}
                                dataTextBold
                            />
                            <Typography
                                style={{
                                    fontSize: '.75rem',
                                    fontWeight: 700,
                                    color: COLORS.BLUE_DARK,
                                }}
                                marginBottom=".25rem"
                                marginLeft="1rem"
                            >
                                {flightDepartureDateTime} {isUtc ? '(UTC)' : ''}
                            </Typography>
                        </Box>
                    )}
                    {isFlightTracked && flightArrivalDateTime && (
                        <Box className={classes.centerContainer}>
                            <IconedData
                                icon={FlightLandIcon}
                                data={flightDetails.arrival?.iata}
                                dataColor={COLORS.BLACK}
                                tooltip={flightDetails.arrival?.name}
                                dataTextBold
                            />
                            <Typography
                                style={{
                                    fontSize: '.75rem',
                                    fontWeight: 700,
                                    color: COLORS.BLUE_DARK,
                                }}
                                marginBottom=".25rem"
                                marginLeft="1rem"
                            >
                                {flightArrivalDateTime} {isUtc ? '(UTC)' : ''}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ChangeFlightNumber;
